import React, { useReducer, useCallback } from "react";
import appReducer from "./reducer";

const AppContext = React.createContext();

const initialState = {
    user: null,
    alerts: [],
    signup: {
        firstName: "",
        email: "",
        password: ""
    }
};

export const useAppState = () => React.useContext(AppContext);

const thunk = (dispatch, state) => {
    return (action) => {
        return (typeof action === "function")? action(dispatch, state) : dispatch(action);
    };
};

export const Provider = ({ children }) => {
    const [state, dispatchBase] = useReducer(appReducer, initialState);

    const dispatch = useCallback(thunk(dispatchBase, state), []);

    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {children}
        </AppContext.Provider>
    );
};