import { userReducer } from "./user";
import { alertReducer } from "./alerts";
import { signupReducer } from "./signup";

export default (state, action) => {
    const {
        user,
        alerts,
        signup
    } = state;

    return {
        user: userReducer(user, action),
        alerts: alertReducer(alerts, action),
        signup: signupReducer(signup, action)
    }
};