import { Zen } from "../utils/firebase";
import { Alert, AlertAction } from "../shared/Alerts";
import { dismissAlert, showAlert } from "./alerts";

export const USER_DID_LOG_IN = "user/session/created";
export const USER_DID_LOG_OUT = "user/session/destroyed";

export const userDidLogIn = (user) => {
    return {
        type: USER_DID_LOG_IN,
        user: user
    };
};

export const userDidLogout = () => {
    return {
        type: USER_DID_LOG_OUT
    }
};

export const createUser = ({ firstName, email, password }) => {
    return (dispatch) => {
        Zen.Auth.createUserWithEmailAndPassword(email, password).then((response) => {
            response.user.updateProfile({
                displayName: firstName
            }).then((user) => {
                dispatch(userDidLogIn(response.user));
            });
        }).catch((error) => {
            const alert = new Alert({
                title: "Failed",
                message: error.message
            });

            alert.addAction(new AlertAction({
                name: "Ok",
                handler: () => {
                    dispatch(dismissAlert(alert.id));
                }
            }));

            dispatch(showAlert(alert));
        });
    };
};

export const userReducer = (state, action) => {
    switch (action.type) {
        case USER_DID_LOG_IN:
            return action.user;
        case USER_DID_LOG_OUT:
            return null
        default:
            return state;
    }
};