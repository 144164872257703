import React, { useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";

export const DynamicWord = ({ words, delay }) => {
    const index = useRef(0);
    const [word, setWord] = useState(words[index.current]);
    const [nextWord, setNextWord] = useState(word);
    const [fade, setFade] = useState(false);
    
    useEffect(() => {
        setFade(true);
        setWord(nextWord);
    }, [nextWord]);

    return (
        <CSSTransition 
            timeout={delay}
            in={fade}
            onExited={() => {
                setFade(true);
            }}
            onEntered={() => {
                let pointer = (index.current === words.length - 1) ? 0 : index.current + 1;
                index.current = pointer;
                setNextWord(words[index.current]);
                setFade(false);
            }}
            classNames="dynamic-word">
            <span className="dynamic-word-enter-done">{word}</span>
        </CSSTransition>
    );
};

DynamicWord.defaultProps = {
    words: [],
    delay: 200
}