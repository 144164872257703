import React, { useEffect, useState } from 'react';
import breathingDiagram from "./images/breathing-diagram.jpg";
import { SignupForm } from "./shared/Forms";
import { AlertServer } from './shared/Alerts';
import { WelcomeWidget } from './beta/WelcomeWidget';
import { useAppState } from './state/provider';
import { Zen } from './utils/firebase';
import { userDidLogout, userDidLogIn } from './state/user';
import { AudioPlayer, BreathingPreview } from "./player";
import magellansThriftShopImage from "./images/magellans-thrift-shop.jpg";
import appSreenImage from "./images/zenmode-app.jpg";
import { Navbar } from './shared/Navbar';
import { SectionTitle } from './shared/SectionTitle';
import { HeroBanner } from './shared/hero';

const sampleAudio = "https://firebasestorage.googleapis.com/v0/b/zenmode-app.appspot.com/o/media%2Faudio%2Fsleep-stories%2Fmagellans-thrift-shop-preview.mp3?alt=media&token=35089da7-33ea-4024-818c-36eb95589b56";

function App() {

    const { state: { user }, dispatch } = useAppState();
    const [scrolled, setScrolled] = useState(false);

    const resize = () => {
        const delta = window.scrollY;

        if (!scrolled && delta > 0) {
            setScrolled(true)
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        Zen.Auth.onAuthStateChanged((user) => {
            if (user) {
                dispatch(userDidLogIn(user));
            } else {
                dispatch(userDidLogout());
            }
        });
    }, [dispatch]);

    useEffect(() => {
        window.addEventListener("scroll", resize);
        
        return () => {
            window.removeEventListener("scroll", resize);
        };
    }, []);
    
    return (
        <div className="app">
            <Navbar scrolled={scrolled} />
            
            <section className="section">
                <div className="container">
                    <div className="row center-xs">
                        <div className="col-xs-12 col-md-9 col-lg-8">
                            <h2>Melt Away Stress & Anxiety In Three Minutes Using This One Genius App</h2>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container">
                    <div className="row center-xs">
                        <div className="col-xs-12 col-md-9 col-lg-8 align-left">
                            <h2>Imagine this:</h2>
                            <p>
                                Your rent is due next week; your engine light 
                                just turned on; you’re having trouble in your relationship and to top it 
                                all off, your boss wants to meet with you tomorrow morning.
                            </p>
                            <p>
                                Now this isn’t actually your life - at least I hope not. But it’s actually not far from 
                                the truth for millions of people. We are all juggling a thousand 
                                different things during the day, but when do we actually take time 
                                out for ourselves?
                            </p>

                            <h3>
                                Right now in the United States, <br />
                                stress & anxiety disorders are at an all time high.
                            </h3>

                            <p>If the above scenario sounds like you then listen up because you’re not alone. You may very well be suffering from depression, anxiety & stress. The symptoms of these mental discomforts are not always clear, but below is a list of ways that these ailments manifest themselves.</p>

                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <h4>Physical Symptoms</h4>
                                    <ul>
                                        <li>trouble sleeping</li>
                                        <li>fast heartbeat</li>
                                        <li>muscle tension</li>
                                        <li>stomachache</li>
                                        <li>headache</li>
                                        <li>rapid breathing</li>
                                        <li>sweating</li>
                                        <li>shaking</li>
                                        <li>dizziness</li>
                                        <li>frequent urination</li>
                                        <li>change in appetite</li>
                                        <li>diarrhea</li>
                                        <li>fatigue</li>
                                    </ul> 
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <h4>Emotional Symptoms</h4>
                                    <ul>
                                        <li>feelings of impending doom</li>
                                        <li>panic or nervousness, especially in social settings</li>
                                        <li>difficulty concentrating</li>
                                        <li>irrational anger</li>
                                        <li>restlessness</li>
                                    </ul>   
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row center-xs">
                        <div className="col-xs-12 col-md-9 col-lg-8 align-left">
                            <h3>Watch Out, Because...</h3>
                            <p>
                                If you have 3 or more of the symptoms listed above, there’s a good 
                                chance you’re experiencing high levels of stress and anxiety. If these 
                                symptoms go on for too long, your physical health can actually 
                                suffer. It’s important to understand when the stressors in your 
                                life are causing negative consequences in your life.
                            </p>

                            <h3>Your Mind Is Way Overactive</h3>
                            <p>With all of the stresses of everyday life occupying your precious mental space - like a stressful job, living paycheck to paycheck, marriage, children, car problems, stressful social situations, etc… It’s no wonder you’re stressed. But there’s something you can do about that - and we’ve perfected it.</p>
                        </div>
                    </div>
                </div>
            </section>
 
            <section className="section dark spacious">
                <div className="container">
                    <div className="row center-xs">
                        <div className="col-xs-12 col-md-8">
                            <h3>The Good News</h3>
                            <p>All of these symptoms, and even the root causes themselves are 100% curable. The best part about it is, they are cured naturally, all without ingesting a single pharmaceutical. If you’d like to live a happier and healthier life, keep reading.</p>                        
                        </div>
                    </div>
                </div>
            </section>               
            
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-md-3">
                            <img src={appSreenImage} />
                        </div>
                        <div className="col-xs-12 col-md-1">

                        </div>
                        <div className="col-xs-12 col-md-6">
                            <h3>Introducing Zen Mode</h3>
                            <p>Our answer to your mental wellbeing. In just three minutes in the morning, you can make a real and positive impact on your mental health. By using our mood & mind changing relaxation app for just moments every day, you’ll regain your health, both mentally and physically.</p>
                            <p>If you’re anything like me, your mind is racing at one million miles per hour while laying in bed trying to sleep. This often kept me awake at night; on occasion until 3 or 4 o-clock in the morning. But after doing a lot of research on the internet, going to expensive therapists and listening to podcasts, I finally found the answer to solving stress & anxiety conundrum.</p>
                            <p>The Zen Mode App snuffs out your sleep issues at the source. We are proud to regularly publish relaxing audio tracks such as bedtime stories, delta wave deep relaxation frequencies, and many other restfulness enhancing tracks to lull you into a deep and relaxing sleep. We make it impossible to stay awake so you can wake up rejuvenated.</p>
                        </div>
                    </div>
                </div>
            </section>

            <HeroBanner />

            <section className="section">
                <div className="container">
                    <div className="row">

                        <SectionTitle title="Sleep Stories" />

                        <div className="col-xs-12 col-md-6 align-right">
                            <h4>With Zen Mode Original Sleep Stories you'll...</h4>
                            <h3>
                                Relax Your Mind and <br />
                                Fall Asleep in Minutes
                            </h3>                            
                            <p>Do you remember as a kid asking your parents to read you a bedtime story? Think back to how safe and calm that made you feel. I bet it’s hard for you to remember actually falling asleep during the story. But you woke up refreshed with tons of energy the next day.</p>
                            <p>So what changed? Yeah, I know, we grew up. But, you know, you don’t have to stop with the bedtime stories. That’s why we created Zen Mode. Just press play on one of our stories and let us do the work. Goodnight.</p>                            
                        </div>
                        <div className="col-xs-12 col-md-1"></div>
                        <div className="col-xs-12 col-md-5">
                            <AudioPlayer 
                                title="Magellan's Thrift Shop"
                                author="Satine Silva"
                                narrator="Dyllan Decker"
                                image={magellansThriftShopImage}
                                sound={sampleAudio} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container">
                    <div className="row">

                        <SectionTitle title="Visual Breathing Techniques" />

                        <div className="col-xs-12 col-md-4">
                            <BreathingPreview />
                        </div>
                        <div className="col-xs-12 col-md-1"></div>
                        <div className="col-xs-12 col-md-7">
                            <h3>Breath Your Way To A Better Day</h3>
                            <h4>With Zen Mode Visual Breathing Techniques</h4>
                            <p>Life happens. We get it. But tense situations don’t need to control you. With Zen Mode’s innovative visual breathing techniques, you’ll be able to confidently grab life by the horns. Simply follow the pulsing circle on your screen with your breath. Inhaling and exhaling on-queue, you’ll notice the world fades away from around you. Once you’re ready, you can come back to the present moment - in a renewed head space. Free from anxiety you can:</p>

                            <ul>
                                <li>Ask that girl out</li>
                                <li>Get that raise you’ve always wanted</li>
                                <li>Wind down during our sleep stories</li>
                                <li>Make better decisions during arguments</li>
                                <li>Confidently choose when buying expensive things</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-md-5">
                            <h3>What you get</h3>

                            <ul className="important check">
                                <li><strong>Fall asleep</strong> in minutes with our original sleep stories</li>
                                <li><strong>Guided Meditations</strong> that relax your body and calm your mind</li>
                                <li>Control anxiety & anger with our <strong>visual breathing techniques</strong></li>
                                <li>Enter deep sleep state with <strong>binaural beats</strong></li>
                                <li><strong>Free</strong> lifetime content updates</li>
                            </ul>
                        </div>
                        <div className="col-xs-12 col-md-1"></div>
                        <div className="col-xs-12 col-md-6">
                            <SignupForm />
                        </div>
                    </div>
                </div>
            </section>

            <AlertServer />
        </div>
    );
}

export default App;
