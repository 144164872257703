import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as PlayIcon } from "../images/play.svg";
import { ReactComponent as PauseIcon } from "../images/pause.svg";

const PlaybackButton = ({ 
    playing, 
    onClick
}) => {

    const renderIcon = () => {
        switch (playing) {
            case true:
                return (
                    <PauseIcon />
                );
            case false:
                return (
                    <PlayIcon />
                );
        }
    };

    return (
        <button className="button playback" onClick={(e) => {
                e.preventDefault();

                onClick();
        }}>
        {renderIcon()}    
        </button>
    );
};

PlaybackButton.defaultProps = {
    playing: false,
    onClick: () => {}
};

export const AudioPlayer = ({ sound, image, title, author, narrator }) => {
    const audio = useRef(new Audio(sound));
    const [playing, setPlaying] = useState(false);

    const updatePlaybackState = () => {
        const paused = audio.current.paused;
        setPlaying(!paused);
    };

    useEffect(() => {
        audio.current.addEventListener("play", updatePlaybackState);
        audio.current.addEventListener("pause", updatePlaybackState);

        return () => {
            audio.current.removeEventListener("play", updatePlaybackState);
            audio.current.removeEventListener("pause", updatePlaybackState);
        };
    });

    const togglePlayback = () => {
        const player = audio.current;
        
        if (player.paused) {
            player.play();
        } else {
            player.pause();
        }
    };

    return (
        <div className="audio-player">
            <div className="image">
                <img src={image} alt={title} />
                <div className="talent">
                    <div className="author">Author: {author}</div>
                    <div className="narrator">Narrator: {narrator}</div>
                </div>
                <div className="sample">Sleep Story Preview</div>
            </div>

            <div className="footer">
                <div className="controls">
                    <div className="pulse green">
                        <PlaybackButton 
                            playing={playing}
                            onClick={togglePlayback} />
                    </div>
                </div>
                <div className="meta">
                    <div className="title">{title}</div>
                </div>
            </div>
        </div>
    )
};

export const BreathingPreview = () => {
    return (
        <video mute loop autoPlay src="https://firebasestorage.googleapis.com/v0/b/zenmode-app.appspot.com/o/media%2Fvideos%2Fzenmode-visual-breathing.mp4?alt=media&token=ad5ff9fd-6ae2-4794-8f55-1b66402851d3"></video>
    );
};