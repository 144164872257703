export const ALERTS_ADD = "alerts/add";
export const ALERTS_DISMISS = "alerts/dismiss";
export const ALERTS_RESET = "alerts/reset";

export const dismissAlert = (id) => {
    return {
        type: ALERTS_DISMISS,
        id: id
    };
};

export const showAlert = (alert) => {
    return {
        type: ALERTS_ADD,
        alert: alert
    }
};

export const resetAlerts = () => {
    return {
        type: ALERTS_RESET
    }
};

export const alertReducer = (state, action) => {
    switch (action.type) {
        case ALERTS_ADD:
            return [
                ...state,
                action.alert
            ];
        case ALERTS_DISMISS:
            return [
                ...state.filter((alert) => {
                    return alert.id != action.id
                })
            ];
        case ALERTS_RESET:
            return [];
        default:
            return state;
    }
};