import React from "react";

export const SectionTitle = ({ title }) => {
    return (
        <div className="section-title">
            <h2>{title}</h2>
            <div className="title-border">
                <div className="border-accent"></div>
            </div>
        </div>
    );
};