import React from "react";
import { useAppState } from "../state/provider";
import { updateSignupForm } from "../state/signup";
import { createUser } from "../state/user";

export const SubscribeForm = ({ data, onSubmit }) => {
    const { state: { signup }, dispatch } = useAppState();
    
    const {
        firstName,
        email,
        password
    } = signup;
    
    return (
        <form className="panel">
            <div className="header">
                <h3>Sign Up</h3>
            </div>        
            <div className="body">
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div className="field">
                            <input placeholder="first name" type="text" className="input" value={firstName} onChange={(e) => {
                                dispatch(updateSignupForm({ firstName: e.target.value }));
                            }} />
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <div className="field">
                            <input placeholder="email" type="email" className="input" value={email} onChange={(e) => {
                                dispatch(updateSignupForm({ email: e.target.value }));
                            }} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div className="field">
                            <input placeholder="password" type="password" className="input" onChange={(e) => {
                                dispatch(updateSignupForm({ password: e.target.value }));
                            }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer center">
                <button className="button" onClick={(e) => {
                    e.preventDefault();
                    // if (!firstName || !email || !password) { return }
                    onSubmit(signup);
                }}>Create Account</button>

                <p className="legal">
                    <a href="https://nuvelio.com/pages/privacy-policy">Privacy Policy</a> &bull; <a href="https://nuvelio.com/pages/terms-of-service">Terms of Service</a>
                </p>
            </div>
        </form>
    );
};

SubscribeForm.defaultProps = {
    data: {},
    onSubmit: () => {}
}

export const EarlyAccessDisclaimer = () => {
    return (
        <div className="box">
            <h3>Get Early Access</h3>
            <p>Sign up now and you’ll be among the first to know when our app is ready.</p>
            <p>When you submit this form, you're creating an account. When the app is finished, you'll be able to use this account to log in. This account gives you instant access to all Zen Mode content.</p>
        </div>
    );
};

export const SignupForm = () => {
    const { state: { signup }, dispatch } = useAppState();
    
    return (
        <SubscribeForm data={signup} onSubmit={(data) => {
            dispatch(createUser(data));
        }} />
    );
};