export const SIGNUP_FORM_MODIFY = "form/signup/modify";

export const updateSignupForm = (updates) => {
    return {
        type: SIGNUP_FORM_MODIFY,
        data: updates
    }
};

export const signupReducer = (state, action) => {
    switch (action.type) {
        case SIGNUP_FORM_MODIFY:
            return Object.assign({}, state, action.data);
        default:
            return state;
    }
};