import firebase from "firebase";
import firebaseConfig from "../config/firebase";

export const Zen = (() => {
    firebase.initializeApp(firebaseConfig);
    
    const auth = firebase.auth();
    
    return {
        Auth: auth
    }
})();