import React, { useEffect, useRef } from "react";
import { v4 as uuid4 } from "uuid";
import ReactDOM from "react-dom";
import { 
    resetAlerts 
} from "../state/alerts";
import { useAppState } from "../state/provider";

export const AlertServer = () => {
    const { state } = useAppState();
        
    const alerts = state.alerts.map((alert, i) => {
        return (
            <UIAlert key={i} alert={alert} />
        );
    });

    return (
        <>
            {alerts}
        </>
    );
};

export class AlertAction {
    constructor({ name, style, handler }) {
        this.style = style;
        this.name = name;
        this.handler = handler;
    }
}

export class Alert {
    constructor({ title, message }) {
        this.title = title;
        this.message = message;
        this.actions = [];
        this.id = uuid4();
    }

    addAction(action) {
        this.actions.push(action);
    }
}

export const UIAlertAction = ({ action }) => {
    const {
        name,
        style,
        handler
    } = action;
    return (
        <button className={`alert-action ${style}`} onClick={(e) => {
            e.preventDefault();
            handler();
        }}>{name}</button>
    );
};

export const UIAlert = ({ alert }) => {
    const { dispatch } = useAppState();
    
    const el = useRef(document.createElement("div"));

    useEffect(() => {
        const modalRoot = document.getElementById("modal-root");
        const alert = el.current;

        modalRoot.appendChild(alert);

        return () => {
            modalRoot.removeChild(alert);
        };
    }, []);

    const renderActions = () => {
        return alert.actions.map((action, i) => {
            return (
                <UIAlertAction key={i} action={action} />
            );
        });
    };

    const {
        title,
        message
    } = alert;

    return ReactDOM.createPortal((
        <div className="modal" onClick={() => {
            dispatch(resetAlerts());
        }}>
            <div className="alert">
                <div className="alert-header">
                    <div className="alert-title">{title}</div>
                </div>
                <div className="alert-message">
                    <p>{message}</p>
                </div>
                <div className="alert-actions">
                    {renderActions()}
                </div>
            </div>
        </div>
    ), el.current);
};