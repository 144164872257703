import React from "react";
import { DynamicWord } from '../shared/DynamicWord';
import heroPoster from "../images/hero-poster";

const heroVideo = "https://firebasestorage.googleapis.com/v0/b/zenmode-app.appspot.com/o/media%2Fvideos%2FZenModeHero.mp4?alt=media&token=4837103c-81fe-48e0-bfa5-19058640f661";
const words = [
    "relax", "fall asleep", "calm down", "meditate", "de-stress", "find peace", "heal", "unwind", "grow", "disconnect"
];

export const HeroBanner = () => {
    return (
        <div className="hero">
            <video playsInline loop autoPlay muted poster={heroPoster} src={heroVideo}></video>
            <div className="content">
                <div className="container">
                    <h2 className="tagline">
                        Your new favorite app <br />
                        to <DynamicWord delay={2000} words={words} />.
                    </h2>
                </div>
            </div>
        </div>
    );
};