import React from "react";
import cx from "classnames";
import { ReactComponent as Logo } from "../images/zenmode-nuvelio.svg";

export const Navbar = ({ scrolled }) => {
    const navbarClasses = cx("navbar", {
        scrolled: scrolled
    });

    return (
        <nav className={navbarClasses}>
            <div className="container">
                <div className="navbar-items">
                    <div className="navbar-brand">
                        <a href="https://zenmode.nuvelio.com" title="Zen Mode - Relaxation Elevated">
                            <Logo />
                        </a>
                    </div>
                    <div className="navbar-item">
                        <h3>Relaxation Elevated</h3>
                    </div>
                </div>
            </div>
        </nav>
    );
};